.why-customer-eng-container {
    min-height: 100%;
    min-width: 100%;
    position: relative;
}

.overlaywhy{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f82c3;
    opacity: 0.7;
}

.videoBgwhy{
    height: 100%;
    position: absolute;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}


.why-backers-container {
    padding-top: 1cm;
    padding-bottom: 1cm;
    padding-left: 1cm;
    padding-right: 1cm;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 60vh;
    width: 100%;
    margin: 0rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 3rem;
}


.why-card{
    transition: 0.5s;
    border-radius: 0.8cm;
    background: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    box-shadow: 5px 10px 18px #090141;

}

/* card  */
.why-header {
    padding-bottom: -0.7cm;
    padding-top: 0.5cm;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    white-space: nowrap;
}

.cta-2 {
    color: #333;
    font-weight: bolder;
    background-image: linear-gradient(90deg, #1f82c3 80%, #ec232b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
    z-index: -1;
    position: relative;
    transition: all 0.5s linear;
}

.cta-1 {
    overflow: hidden;
    position: relative;
}

/* .why-title {
    font-size: calc(2.5rem + 0.1rem);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    overflow: hidden;
    text-align: center;
} */

.why-title{
    font-size: calc(2.5rem + 0.1rem);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    overflow: hidden;
    text-align: center;
    color: #1f82c3 ;
    display: block;
    width: 74%;
    height: 120%;
    background: #243474;
      /* background-image: none; */
    z-index: 2;
    /* background-image: linear-gradient(to bottom, #e5ebef, #dce0e8, #d6d5e0, #d3cad5, #d0bec7, #cfb7c2, #ceb0bd, #cda9b7, #c8a6c1, #fff, #a5a7da, #83abe2); */
    background-image: radial-gradient(circle, #dbc5c5ab, #eadddd, #cebcbc, #f9fcff, #f4faff, #eedddd96, #fff0, #a3bad0, #9ab7d5, #91b3d9, #8aafde, #1f82c3);
    /* border-radius: 2rem; */
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    }



.subtitle {
    margin-top: 2rem;
    width: fit-content;
}

.why-card:hover {
    transition: all 0.5s linear;
    transform: scale(1.08);
    box-shadow: 5px 5px 10px #ec232a8e;;
}



.why-card-top {
    height: calc(15rem + .5rem);
    display: flex;
    background: white;
}

.why-card-top img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.5s linear;
}

.why-card-bottom {
    background: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.bottom-title {
    text-align: center;

}

.bottom-desc {
    text-align: center;
    padding: 1rem 0;
    font-size: calc(0.8rem + 0.1rem);
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .bottom-desc:after {
    content:"";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 48px;
    z-index: 1; */
    /* border: 1px solid red;
    pointer-events:initial;
  
  }

.bottom-desc:hover:after{
    cursor: pointer;
  }

.tooltip {
  width: 60%;
  visibility: hidden;
  background-color: #fff;
  padding: 20px;
  /* -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3); */
  /* opacity: 0;
  transition: opacity 0.5s ease;
}


.bottom-desc:hover + .tooltip {
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
} */


