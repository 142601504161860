body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 95%;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

button {
  outline: none;
  border: none;
  background: none;
}