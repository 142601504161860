@import url(https://fonts.googleapis.com/css2?family=Pattaya&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 95%;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

button {
  outline: none;
  border: none;
  background: none;
}
.intro-wrapper {
    overflow-x: hidden;
    position: relative;

}

.intro-container {
    /* height: 100vh; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    position: relative;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.overlay{
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f82c3;
    opacity: 0.5;
}
.videoBg{
    height: 100vh;
    width: 100%;
    z-index: -3;
    object-fit: cover;
}

.intro-container>* {
    color: white;
}

nav {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.cta {
    overflow: hidden;
    display: inline-flex;
    margin: 0;
    padding: 0;
    transition: font-size 1s linear;
}

.logo-container {
    width: 40%;
    display: block;
}

.logo-container img {
    width: 200px;
    display: block;
    padding: 5px 5px 5px 5px;
    object-fit: contain;
}

.menu-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.burger {
    cursor: pointer;
    transition: all .3s linear;
}

.burger-2 {
    top: 5%;
    position: absolute;
    fill: black;
}

.burger-title {
    margin-left: 0.5rem;
    cursor: pointer;
    opacity: 1;
    display: inline-block;
    transition: all .3s linear;
    height: 100%;
    text-shadow: 0 2px 2px rgba(0, 0, 0, .5);
}

.menu-container:hover .burger-title {
    opacity: 1;
    -webkit-transform: translate(-10%);
            transform: translate(-10%);
    transition: all .3s linear;
}

.menu-container:hover .burger {
    -webkit-transform: translate(-10%);
            transform: translate(-10%);
    transition: all .3s linear;
}


.vertical-shades, .intro-circles, .intro-horizontal_shades, .men-at-work {
    position: absolute;
    z-index: -1;

}

.intro-horizontal_shades {
    top: 0%;
    left: 40%;
}

.intro-circles {
    top: 40%;
    left: 5%;
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: all 0.2s ease-in-out;
    -webkit-animation: moving-circles 10s linear infinite;
            animation: moving-circles 10s linear infinite;
}

.vertical-shades {
    top: 10%;
    left: 0%;
    transition: all 0.2s ease-in-out;
    animation: moving-verticle-shades 5s linear infinite alternate-reverse;
}

.men-at-work {
    top: 30%;
    left: 70%;
}
hr.introHr{
    width: 100%;
    background-color: #ec232b;
}
.homecontent{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.intro-content {
    display: flex;
    justify-content: space-between;
    width: 40%;
    flex-direction: column;
    padding-left: 1.5cm;
    bottom: 0;
    margin: 1rem auto 3rem auto;
    position: absolute;
    align-self: flex-start;
}

/* content left */
.content-title {
    font-size: 3rem;
    font-family: 'Source Sans Pro', sans-serif;

}


.content-subtitle {
    padding: 0rem 0rem 1rem 0rem;
}

/* .content-contact_us {
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    background: #1f82c3;
    border-radius: 0.5rem;
    padding: 0.5rem 3rem;
    border: 1px solid #ec232b;
    color: white;
    cursor: pointer;
    transition: color 1s ease-in-out;
    box-shadow: 0px 10px 10px rgb(30, 0, 48, 0.1), 0px 20px 20px rgb(30, 0, 48, 0.1);
    margin-top: 1rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
} */

.content-contact_us {
    display: inline-block;
    font-family: Poppins,sans-serif;
    font-weight: 600;
    font-size: 1rem;
    background: #ffffff84;
    border-radius: 2.5rem;
    padding: .5rem 3rem;
    border: 1px solid #ffffffc2;
    color: #fff;
    cursor: pointer;
    transition: color 1s ease-in-out;
    box-shadow: 0 10px 10px rgba(30,0,48,.1),0 20px 20px rgba(30,0,48,.1);
    margin-top: 1rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-backdrop-filter: blur(2rem);
            backdrop-filter: blur(2rem);
    box-shadow: 2px 1px 2px 1px #0202022e;
    border: none;
    box-shadow: 10px 10px 14px 1px rgba(00,00,00,0.2);
  }

.navigation{
    border-radius: 5px;
    width: 100%;
    color: #1f82c3;
    position: relative;
    display: flex;
    box-shadow: 2px 1px 2px 1px #1b181853;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0.25px;
    transition: 0.3s;
    z-index: 3;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.56);
}

.navigationbuttons{
    /* justify-content: space-evenly; */
    display: flex;
    align-items: center;
    width: 60%;

}
.nav-items {
    display: flex;
    color: #1f82c3;
    /* width: 100%; */
    -webkit-padding-start: 5%;
            padding-inline-start: 5%;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    z-index: 1;
    transition: 0.5s;
    font-family: "Poppins", sans-serif;
}

.nav-items li{
    list-style-type: none;
}

.nav-items-mobile li{
    list-style-type: none;
}

.nav-items li a{
    color: #1f82c3;
    text-decoration: none;
    display: block;
    padding: 20px 25px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    transition: 0.3s;
    position: relative;
    height: 60px;
}

.nav-items-mobile li a{
    color: #1f82c3;
    text-decoration: none;
    display: block;
    padding: 30px 25px;
    font-size: 18px;
    line-height: 1;
    transition: 0.3s;

}

.nav-items li a:hover{
    color:  #1f82c3;
    font-weight: 900;
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
    /* padding: 25px 25px 25px 25px; */

}

.nav-items-mobile li a:hover{
    box-shadow: 0 -5px 0px #fff inset, 500px 0 0 #1f82c3 inset;
    padding: 35px 25px 45px 25px;

}


.hamburger{
    position: absolute;
    width: 30px;
    height: 4px;
    background: #1f82c3;
    border-radius: 10px;
    cursor: pointer;
    z-index: 2;
    transition: 0.3s;
}

.hamburger:before, .hamburger:after{
    content: "";
    position: absolute;
    height: 3px;
    right: 0;
    background: #1f82c3;
    border-radius: 10px;
    transition: 0.3s;
}

.hamburger:before{
    top: -10px;
    width: 20px;    
}

.hamburger:after{
    top: 10px;
    width: 25px;
}

.toggle-menu{
    position: relative;
    width: 30px;
    height: 100%;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
}



.hamburger, .toggle-menu, .nav-items-mobile{
    display: none;
}

@media screen and (max-width: 992px) {

    .nav-items{

        display: none;
    }
    .men-at-work {
        top: 30%;
        left: 100%;
    }

    .hamburger, .toggle-menu{
        display: block;
    }
    .nav-items-mobile{
        z-index: 1;
        transition: 0.5s;
        font-family: "Poppins", sans-serif;

        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: white;
        color: #1f82c3;
        right: -330px;
        width: 300px;
        height: 100vh;
        padding-top: 65px;
    }

    .nav-items-mobile li {
        width: 100%;
    }
    .nav-items-mobile li a,
    .nav-items-mobile li a:hover{
        padding: 30px;
        font-size: 15 px;
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset;
    }
    .navigation{
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.56);
        position: absolute;
        z-index: 3;
        padding: 10px 20px;
    }

    .contact-form {
        left: -100;
    }

    .contact_us_form {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 0%;
        transition: left .5s linear;
    }
    .contact_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 0.5rem;
        bottom: 0;
        padding: 2rem;
    }
    
    .logo {
        left: 0;
        flex: 1 1;
        top: 0;
        text-shadow: 0 4px 4px rgba(0, 0, 0, .5);
        font-size: 0.9rem;
        font-weight: bolder;
        letter-spacing: .1rem;
    }
}

.content-contact_us:hover {
    color: #ffffff;
    font-weight: 1000;
    
}

.content-contact_us:hover .background {
    width: 150%;
    height: 500%;

}

hr {
    width: 100%;
    color: rgba(255, 255, 255, 0.801);
}
.contact_us_form {
    height: 100vh;
    position: absolute;
    z-index: 3;
    top: 0%;
    transition: left .5s linear;
}

/*============================================= Contact Form section */
.form-title {
    font-size: calc(2.5rem + 0.2rem);
    font-weight: 700;
    background: linear-gradient(#ec232b, #1f82c3);
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Source Sans Pro', sans-serif;
}

.contact-form {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 2rem;
}

.form-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cancel-container {
    position: relative;
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: row;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    cursor: pointer;
}

.cancel-container .plus-horiz {
    position: absolute;
    width: 25px;
    height: 1px;
    background-color: #228dff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    pointer-events: none;
    transition: all 0.5s ease-in;
}

.cancel-container .plus-vert {
    position: absolute;
    width: 1px;
    height: 25px;
    background-color: #228dff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    pointer-events: none;
    transition: all 0.5s ease-in;
}

.errors {
    color: red;
    z-index: 5;
    position: absolute;
    font-size: 0.7rem;
}

.contact-form i {
    cursor: pointer;
}

.hide-contact {
    display: flex;
    place-content: center flex-end;
}

.title {
    color: var(--secondary-color);
    font-size: 2rem;
}

/* form content */
.contact-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.contact-left, .contact-right, .signin-left {
    flex: 1 1;
}

.contact-left {
    margin-top: 2rem;
}

.contact-us_description {
    font-size: 1rem;
    margin: 1rem 0rem;
    line-height: 2rem;
    display: flex;
    justify-content: flex-start;
    text-align: justify;
}

.contact-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    padding: 0rem;
    color: #474242;
    font-size: 1.2rem;
}

.input-container {
    position: relative;
    margin: 1rem 0rem;
    font-size: 1.2rem;
}

.input:hover {
    background: #541f701a;
}

.input-number{
    font-size: 1.2rem;
}

.placeholder {
    position: relative;
    z-index: 1;
    pointer-events: none;
    padding: 0.5rem 0rem;
    font-size: 1.2rem;
    opacity: 0.7;
    -webkit-transform-origin: left;
            transform-origin: left;
    color: #1E0030;
}

.placeholder-comment {
    padding: 0.2rem;
}

.line-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: visible;


}

/* Join button */
.join-btn {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    border-radius: 2rem;
    padding: 0.5rem 3rem; 
    margin: 3rem auto 0rem auto;
    border: none;
    color: white;
    font-weight: bold;
    background: #1f82c3;
    width: 50%;
    cursor: pointer;
    position: relative;
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 10px 10px rgb(30, 0, 48, 0.1), 0px 20px 20px rgb(30, 0, 48, 0.1);
}

.sendbtn{
    padding-top: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.join-btn:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.158), 0px 5px 5px #1e00303b;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

/* character svg */


form input, input {
    width: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    font-size: 1rem;
}

textarea {
    border: 2px solid rgba(0, 0, 0, 0.158);
    font-size: 0.7rem;
    position: absolute;
    outline: none;
    width: 100%;
    height: 300%;
    top: 0%;
    padding: 0.2rem;
    border-radius: 0.4rem;
    overflow: auto;
    resize: vertical;
}

/* contact us Thank you message */
.submitted {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 0.7rem;
    color: #777474;
    pointer-events: none;
    text-align: center;
    opacity: 0;
}

.contact_info {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .9rem;
    bottom: 0;
    padding: 2rem;
}

/* .left_info{
    display: flex;
}

.right_info{
    display: flex;
} */
.right_info>p, .left_info>p {
    padding: .2rem 0;
}


.vertical_line {
    height: 4rem;
    width: 1px;
    border: 1px solid rgba(78, 78, 233, .2);
}

/* Media queries */
@media screen and (min-width:1024px) {
    /* for desktops*/
    .contact-form {
        width: 100%;
        left: -50;
    }
    
    .logo {
        padding-top: 0.3cm;
        padding-left: 0.5cm;
        padding-right: 00.5cm;
        flex: 1 1;
        top: 0;
        text-shadow: 0 4px 4px rgba(0, 0, 0, .5);
        font-size: 1rem;
        font-weight: bolder;
        letter-spacing: .1rem;
    }
    .navigationbuttons{
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 60%;

    
    }
    .nav-items{
        font-size: 0.8rem;
        width:100%;
    }
    .navigation{
        border-radius: 5px;
        width: 100%; 
        position: fixed;
        top: 0;
        color: #1f82c3;
        height: auto;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.56);
        z-index: 3;
    }

    .videoBg{
        height: 100vh;
        width: 100%;
        object-fit: cover;
    }

    .line-svg {
        width: 50%;
    }

}

@media screen and (max-width:768px){
    .contact-form {
        left: -100;
    }
    .logo-container {
        width: 100%;
        display: block;
    }

    .logo-container img {
        width: 100px;
        display: block;
        padding: 0px 0px 0px 0px;
        object-fit: contain;
    }

    .navigationbuttons{
        justify-content: flex-end;
        display: flex;
        align-items: center;
        width: 60%;
    
    }

    .contact_us_form {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 0%;
        transition: left .5s linear;
    }
    .contact_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 0.5rem;
        bottom: 0;
        padding: 2rem;
    }
    
    .logo {
        left: 0;
        flex: 1 1;
        top: 0;
        text-shadow: 0 4px 4px rgba(0, 0, 0, .5);
        font-size: 0.9rem;
        font-weight: bolder;
        letter-spacing: .1rem;
    }

}



/* animations */

@-webkit-keyframes moving-circles {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes moving-circles {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes moving-verticle-shades {
    from {
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
    }

    to {
        -webkit-transform: translateY(50%);
                transform: translateY(50%);
    }
}

@keyframes moving-verticle-shades {
    from {
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
    }

    to {
        -webkit-transform: translateY(50%);
                transform: translateY(50%);
    }
}

@-webkit-keyframes grow-font {
    from {
        font-size: 100rem;
    }

    to {
        font-size: 3rem;
    }

}

@keyframes grow-font {
    from {
        font-size: 100rem;
    }

    to {
        font-size: 3rem;
    }

}
.page-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap{
  flex:1 1
}
.partners-container {
    height: 100%;
    width: 100%;
    position: relative;

}

.partners-intro {
    width: 100%;
    padding-top: 4rem;
    /* padding-bottom: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    white-space: no-wrap;
    justify-content: space-between;
}

/* .intro-title {

    color: #333;
    font-weight: bolder;
    background-image: linear-gradient(90deg, #ec232b, #1f82c3 60%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
    z-index: -1;
    position: relative;
    transition: all 0.5s linear;
} */

.intro-title{
    display: inline-flex;
    font-size: calc(2.5rem + 0.1rem);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    overflow: hidden;
    text-align: center;
    color: #1f82c3 ;
    display: block;
    width: 74%;
    height: 140%;
    background: #ac3b3b2e;
      /* background-image: none; */
    z-index: 2;
    /* background-image: linear-gradient(to bottom, #e5ebef, #dce0e8, #d6d5e0, #d3cad5, #d0bec7, #cfb7c2, #ceb0bd, #cda9b7, #c8a6c1, #fff, #a5a7da, #83abe2); */
    background-image: radial-gradient(circle, #dbc5c547, #eadddd8a, #cebcbc58, #f9fcff, #f4faff, #eedddd33, #fff0, #a3bad0, #9ab7d5, #91b3d9, #8aafde59, #1f81c363);
    /* border-radius: 2rem; */
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    }

.intro-title b {
    font-size: calc(1rem + 0.1rem);
    /* color: red; */

}

.partners-inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
    background: linear-gradient(to left, #ffffff 0%, #1f82c3 100%);
}

.productcards{
    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    position: relative;
    height: 30vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.productlogo{   
    width: 100%;
    display: flex;
}
.logoDiv{
    width: 100%;
    display: flex;    
    height: 20vh;
    margin: 15% auto;
   
}
.why-customer-eng-container {
    min-height: 100%;
    min-width: 100%;
    position: relative;
}

.overlaywhy{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f82c3;
    opacity: 0.7;
}

.videoBgwhy{
    height: 100%;
    position: absolute;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}


.why-backers-container {
    padding-top: 1cm;
    padding-bottom: 1cm;
    padding-left: 1cm;
    padding-right: 1cm;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 60vh;
    width: 100%;
    margin: 0rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 3rem;
    gap: 3rem;
}


.why-card{
    transition: 0.5s;
    border-radius: 0.8cm;
    background: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    box-shadow: 5px 10px 18px #090141;

}

/* card  */
.why-header {
    padding-bottom: -0.7cm;
    padding-top: 0.5cm;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    white-space: nowrap;
}

.cta-2 {
    color: #333;
    font-weight: bolder;
    background-image: linear-gradient(90deg, #1f82c3 80%, #ec232b);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
    z-index: -1;
    position: relative;
    transition: all 0.5s linear;
}

.cta-1 {
    overflow: hidden;
    position: relative;
}

/* .why-title {
    font-size: calc(2.5rem + 0.1rem);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    overflow: hidden;
    text-align: center;
} */

.why-title{
    font-size: calc(2.5rem + 0.1rem);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    overflow: hidden;
    text-align: center;
    color: #1f82c3 ;
    display: block;
    width: 74%;
    height: 120%;
    background: #243474;
      /* background-image: none; */
    z-index: 2;
    /* background-image: linear-gradient(to bottom, #e5ebef, #dce0e8, #d6d5e0, #d3cad5, #d0bec7, #cfb7c2, #ceb0bd, #cda9b7, #c8a6c1, #fff, #a5a7da, #83abe2); */
    background-image: radial-gradient(circle, #dbc5c5ab, #eadddd, #cebcbc, #f9fcff, #f4faff, #eedddd96, #fff0, #a3bad0, #9ab7d5, #91b3d9, #8aafde, #1f82c3);
    /* border-radius: 2rem; */
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    }



.subtitle {
    margin-top: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.why-card:hover {
    transition: all 0.5s linear;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
    box-shadow: 5px 5px 10px #ec232a8e;;
}



.why-card-top {
    height: calc(15rem + .5rem);
    display: flex;
    background: white;
}

.why-card-top img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.5s linear;
}

.why-card-bottom {
    background: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.bottom-title {
    text-align: center;

}

.bottom-desc {
    text-align: center;
    padding: 1rem 0;
    font-size: calc(0.8rem + 0.1rem);
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .bottom-desc:after {
    content:"";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 48px;
    z-index: 1; */
    /* border: 1px solid red;
    pointer-events:initial;
  
  }

.bottom-desc:hover:after{
    cursor: pointer;
  }

.tooltip {
  width: 60%;
  visibility: hidden;
  background-color: #fff;
  padding: 20px;
  /* -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3); */
  /* opacity: 0;
  transition: opacity 0.5s ease;
}


.bottom-desc:hover + .tooltip {
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
} */



.main-footer{
    color:white;
    background-image: linear-gradient(to right,  #1f82c3 0%, #1f82c3 100%);
    /* padding-top: 3em; */
    position: relative;
    width: 100%;
    /* margin-top: auto; */
    font-weight: bold;
}

.left_info{
    padding-left: 5%;
    padding-right: 5%;
}

.right_info{
    padding-left: 5%;
    padding-right: 5%;
}

.footer-content{
    /* width: 90%; */
    /* margin: auto;
    width: 80%;
    height: 30vh;
    padding: 10px */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: .9rem;
    bottom: 0;
    padding: 1rem;
    /* padding-left: 20%;
    padding-right: 20%; */

    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
    grid-gap: 1rem 5rem;
    width: 80%;
    margin: 2rem auto;
    padding-left: 5cm; */
}

.vertical_line{
    height: auto;
}

/* .list-unstyled{
    float: left;
    width: %;
    
} */
.row2{
    margin: auto;
    width: 100%;
    /* height: 1.5cm; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    align-items: center;
    align-self: center;
    line-height: 2.3;
}

@media screen and (max-width: 992px) {
    .row2{
        margin: auto;
        font-size: 0.5rem;
        width: 100%;
        padding: 5px;
    }
}

.nepservlogo{
    /* width: 3cm; */
    /* align-items: center; */
    vertical-align: bottom;
    height: 2.3em;
}
.row:after {
    content: "";
    display: table;
    clear: both;
  }

/* .col{
    width: 30%;
} */
.backers-contaier {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
}

.backersInnerContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    height: 30vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.backerlogo{
    /* padding-inline: 10%; */
    width: 100%;
    display: flex;
    

}

.overlayPartners{
    position: absolute;
    /* top: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f82c3;
    opacity: 0.5;
}
.videoBgPartners{
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.backers-top {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    white-space: no-wrap;
    justify-content: space-between;
}

.backers-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 5rem auto;
    padding-left: 5cm;
}

.backer{
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
}
.backer img {
    width: 90%;
    margin: 3rem auto;
    height: 90%;
    object-fit: contain;
    transition: all 1s linear;
}

.whole-backers-container{
    width: 100%;
    height: 100%;
    position: relative;
}

.backers-title {
    font-size: calc(3rem + 0.1rem);
    font-weight: 700;
    background-image: linear-gradient(90deg, #5fffc2, white, #5fffc2);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 2;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    text-shadow: 0px 10px 10px rgb(0, 0, 0, 0.1);
}

.backers-design {
    position: absolute;
    top: 50%;
    left: 10%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 20%;
    z-index: -1;
    opacity: 0.5;
    -webkit-filter: blur(0.5rem);
            filter: blur(0.5rem);
}
.logoDiv{
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    height: 30vh;
    align-items: center;
    margin: 10% auto;
}
