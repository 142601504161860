.partners-container {
    height: 100%;
    width: 100%;
    position: relative;

}

.partners-intro {
    width: 100%;
    padding-top: 4rem;
    /* padding-bottom: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    white-space: no-wrap;
    justify-content: space-between;
}

/* .intro-title {

    color: #333;
    font-weight: bolder;
    background-image: linear-gradient(90deg, #ec232b, #1f82c3 60%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
    z-index: -1;
    position: relative;
    transition: all 0.5s linear;
} */

.intro-title{
    display: inline-flex;
    font-size: calc(2.5rem + 0.1rem);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    overflow: hidden;
    text-align: center;
    color: #1f82c3 ;
    display: block;
    width: 74%;
    height: 140%;
    background: #ac3b3b2e;
      /* background-image: none; */
    z-index: 2;
    /* background-image: linear-gradient(to bottom, #e5ebef, #dce0e8, #d6d5e0, #d3cad5, #d0bec7, #cfb7c2, #ceb0bd, #cda9b7, #c8a6c1, #fff, #a5a7da, #83abe2); */
    background-image: radial-gradient(circle, #dbc5c547, #eadddd8a, #cebcbc58, #f9fcff, #f4faff, #eedddd33, #fff0, #a3bad0, #9ab7d5, #91b3d9, #8aafde59, #1f81c363);
    /* border-radius: 2rem; */
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    }

.intro-title b {
    font-size: calc(1rem + 0.1rem);
    /* color: red; */

}

.partners-inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
    background: linear-gradient(to left, #ffffff 0%, #1f82c3 100%);
}

.productcards{
    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    position: relative;
    height: 30vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.productlogo{   
    width: 100%;
    display: flex;
}
.logoDiv{
    width: 100%;
    display: flex;    
    height: 20vh;
    margin: 15% auto;
   
}