.main-footer{
    color:white;
    background-image: linear-gradient(to right,  #1f82c3 0%, #1f82c3 100%);
    /* padding-top: 3em; */
    position: relative;
    width: 100%;
    /* margin-top: auto; */
    font-weight: bold;
}

.left_info{
    padding-left: 5%;
    padding-right: 5%;
}

.right_info{
    padding-left: 5%;
    padding-right: 5%;
}

.footer-content{
    /* width: 90%; */
    /* margin: auto;
    width: 80%;
    height: 30vh;
    padding: 10px */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: .9rem;
    bottom: 0;
    padding: 1rem;
    /* padding-left: 20%;
    padding-right: 20%; */

    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
    grid-gap: 1rem 5rem;
    width: 80%;
    margin: 2rem auto;
    padding-left: 5cm; */
}

.vertical_line{
    height: auto;
}

/* .list-unstyled{
    float: left;
    width: %;
    
} */
.row2{
    margin: auto;
    width: 100%;
    /* height: 1.5cm; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    align-items: center;
    align-self: center;
    line-height: 2.3;
}

@media screen and (max-width: 992px) {
    .row2{
        margin: auto;
        font-size: 0.5rem;
        width: 100%;
        padding: 5px;
    }
}

.nepservlogo{
    /* width: 3cm; */
    /* align-items: center; */
    vertical-align: bottom;
    height: 2.3em;
}
.row:after {
    content: "";
    display: table;
    clear: both;
  }

/* .col{
    width: 30%;
} */