.backers-contaier {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
}

.backersInnerContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    height: 30vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.backerlogo{
    /* padding-inline: 10%; */
    width: 100%;
    display: flex;
    

}

.overlayPartners{
    position: absolute;
    /* top: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f82c3;
    opacity: 0.5;
}
.videoBgPartners{
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.backers-top {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    white-space: no-wrap;
    justify-content: space-between;
}

.backers-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 5rem auto;
    padding-left: 5cm;
}

.backer{
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
}
.backer img {
    width: 90%;
    margin: 3rem auto;
    height: 90%;
    object-fit: contain;
    transition: all 1s linear;
}

.whole-backers-container{
    width: 100%;
    height: 100%;
    position: relative;
}

.backers-title {
    font-size: calc(3rem + 0.1rem);
    font-weight: 700;
    background-image: linear-gradient(90deg, #5fffc2, white, #5fffc2);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 2;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    text-shadow: 0px 10px 10px rgb(0, 0, 0, 0.1);
}

.backers-design {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 20%;
    z-index: -1;
    opacity: 0.5;
    filter: blur(0.5rem);
}
.logoDiv{
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    height: 30vh;
    align-items: center;
    margin: 10% auto;
}